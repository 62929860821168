<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div class="block">
            <h1 class="title block">Plans</h1>
            <h2 class="subtitle block">
              Change subscription plans
            </h2>
          </div>
          <div class="block">
            <button @click="createPlan()" class="button is-primary">
              Create Plan
            </button>
          </div>
          <div v-for="plan in plans" :key="plan.id" class="plan box block">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  v-model="plan.name"
                  class="input"
                  placeholder="Text input"
                  type="text"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Price</label>
              <div class="control">
                <input
                  v-model.number="plan.price"
                  class="input"
                  placeholder="USD"
                  type="number"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Duration</label>
              <div class="control">
                <input
                  v-model.number="plan.duration"
                  class="input"
                  placeholder="days"
                  type="number"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Status</label>
              <div class="control">
                <div class="select">
                  <select v-model="plan.status">
                    <option :value="'active'">Active</option>
                    <option :value="'disabled'">Disabled</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button @click="updatePlan(plan)" class="button is-link">
                  Save
                </button>
              </div>
              <div class="control">
                <button
                  @click="deletePlan(plan)"
                  class="button is-danger is-light"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { db } from "@/firebase";

export default {
  name: "MembershipPlansView",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      plans: [],
    };
  },
  firestore: {
    plans: db.collection("plans"),
  },
  methods: {
    createPlan() {
      this.$firestoreRefs.plans.add({
        name: "",
        price: 150,
        duration: 30,
        status: "disabled",
      });
    },
    updatePlan(plan) {
      this.$firestoreRefs.plans.doc(plan.id).update(plan);
    },
    deletePlan(plan) {
      this.$firestoreRefs.plans.doc(plan.id).delete();
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background-color: var(--card-background-color);
};
</style>
