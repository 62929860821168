<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
            class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div class="block">
            <h1 class="title block">Staff</h1>
            <h2 class="subtitle block">
              Manage staff and staff permissions
            </h2>
          </div>
          <div class="block">
            <button @click="toggleMembershipPageView()" class="button is-primary">{{ membershipPageViewStatus }} membership page view</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import {db} from "@/firebase";
import Mobilemenu from "@/components/Mobilemenu";
import AccountService from "../services/AccountService";

export default {
  name: "SettingsStaffView",
  components: {
    Sidebar,
    Mobilemenu
  },
  data() {
    return {
      staff: null,
    }
  },
  firestore: {
    staff: db.collection('settings').doc('staff')
  },
  computed: {
    membershipPageViewStatus() {
      if (this.staff) {
        return this.staff.membershipPageView ? 'Disable' : 'Enable'
      } else {
        return 'Error'
      }
    }
  },
  methods: {
    async toggleMembershipPageView() {
      try {
        await this.$firestoreRefs.staff.update({
          membershipPageView: !this.staff.membershipPageView
        })
        alert('Updated');
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>
